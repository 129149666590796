import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
import logo from '../../assets/images/Saja_Logo black.gif';
import menu from '../../assets/icons/menu.svg';
import close from '../../assets/icons/close.svg';

export default function Navbar() {
	const [openMenu, setOpenMenu] = useState(false);
	const [activeItem, setActiveItem] = useState('/');
	const [showCompaniesDropdown, setShowCompaniesDropdown] = useState(false);
	const [showBrandsDropdown, setShowBrandsDropdown] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const [hasShadow, setHasShadow] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 0) {
				setHasShadow(true);
			} else {
				setHasShadow(false);
			}
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);


	useEffect(() => {
		setActiveItem(location.pathname);
	}, [location.pathname]);

	const toggleMenu = () => {
		setOpenMenu((prev) => !prev);
	};

	const handleItemClick = (path) => {
		setActiveItem(path);
		navigate(path);
		setOpenMenu(false);
		window.scrollTo(0, 0);

	};

	return (
		<nav className={` ${styles['navbar']} ${hasShadow ? styles['shadow'] : " "} `} >
			<img src={logo} className={styles['navbar-logo']} alt='Logo' onClick={() => handleItemClick('/')} />
			<ul className={`${styles['navbar-menu']} ${openMenu ? styles['open'] : styles['close']}`}>
				<div className={styles['close-menu']} onClick={toggleMenu}>
					<img src={close} alt='Close Menu' />
				</div>

				<li className={`${styles['navbar-menu-item']} ${activeItem === '/about' ? styles['active'] : ''}`} onClick={() => handleItemClick('/about')}>
					about
				</li>
				<li
					className={`${styles['navbar-menu-item']} ${activeItem === '/why-saja' ? styles['active'] : ''}`}
					onClick={() => handleItemClick('/why-saja')}>
					why saja?
				</li>
				<li
					className={`${styles['navbar-menu-item']} ${activeItem === '/our-brands' ? styles['active'] : ''}`}
					onClick={() => handleItemClick('/our-brands')}
					onMouseOver={() => setShowBrandsDropdown(true)}
					onMouseLeave={() => setShowBrandsDropdown(false)}>
					our brands
					{showBrandsDropdown && (
						<div className={`${styles['navbar-menu-item-dropdown']} ${styles['navbar-menu-item-companies']}}`}>
							<a href='/our-brands#mada'>Mada</a>
							<a href='/our-brands#meadows'>Meadows</a>
							<a href='/our-brands#stempowers'>Stempowers</a>
						</div>
					)}
				</li>
				<li
					className={`${styles['navbar-menu-item']} ${activeItem === '/our-services' ? styles['active'] : ''}`}
					onClick={() => handleItemClick('/our-services')}>
					our services
				</li>
				<li
					className={`${styles["navbar-menu-item"]} ${activeItem === "/news" ? styles["active"] : ""
						}`}
					onClick={() => handleItemClick("/news")}
				>
					news
				</li>
				<li
					className={`${styles['navbar-menu-item']} ${activeItem === '/affiliated-company' ? styles['active'] : ''}`}
					onClick={() => handleItemClick('/affiliated-company')}
					onMouseOver={() => setShowCompaniesDropdown(true)}
					onMouseLeave={() => setShowCompaniesDropdown(false)}>
					Affiliated companies
					{showCompaniesDropdown && (
						<div className={`${styles['navbar-menu-item-dropdown']} ${styles['navbar-menu-item-companies']}}`}>
							<a href='/affiliated-company#innovera'>Innovera</a>
							<a href='/affiliated-company#appenza'>Appenza</a>
							<a href='/affiliated-company#neowell'>Neowell</a>
						</div>
					)}
				</li>
				<li
					className={`${styles["navbar-menu-item"]} ${activeItem === "/who-we-are" ? styles["active"] : ""
						}`}
					onClick={() => handleItemClick("/who-we-are")}
				>
					who we are
				</li>
				<button onClick={() => handleItemClick('/contact-us')} className={styles['navbar-menu-item-action']}>
					contact us
				</button>
			</ul>
			<button onClick={() => handleItemClick('/contact-us')} className={styles['navbar-action']}>
				contact us
			</button>
			<img src={menu} onClick={toggleMenu} className={styles['navbar-menu-action']} alt='Open Menu' />
		</ nav>
	);
}
